import { Link } from 'gatsby'
import React from 'react'

import social1 from '../assets/images/social/iconmonstr-twitter-4.svg'
import social2 from '../assets/images/social/iconmonstr-facebook-4.svg'
import social3 from '../assets/images/social/iconmonstr-pinterest-4.svg'
import social4 from '../assets/images/social/iconmonstr-linkedin-4.svg'
import social5 from '../assets/images/social/iconmonstr-instagram-14.svg'

import { trackSocialShare, getNavClickDetails } from '../analytics'

class Footer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.fireAnalytics = this.fireAnalytics.bind(this)
    this.fireNavLink = this.fireNavLink.bind(this)
  }

  fireAnalytics(e) {
    trackSocialShare(e.currentTarget.dataset.social)
  }

  fireNavLink(e) {
    getNavClickDetails(e.currentTarget.dataset.navlink, 'footer')
  }

  render() {
    return (
      <div>
        <nav id="footer" className="navbar navbar-expand-lg navbar-light pt-4">
          <div class="container">
            <ul class="navbar-nav ">
              <li class="nav-item">
                <Link className="nav-link navbar-brand" to="/">
                  HOME
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="chairmans-letters"
                  className="nav-link navbar-brand"
                  to="/chairmans-letters"
                >
                  CHAIRMAN'S LETTERS
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="who we are"
                  className="nav-link navbar-brand"
                  to="/who-we-are"
                >
                  WHO WE ARE
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="about us"
                  className="nav-link d-none d-md-inline-block"
                  to="/about-us"
                >
                  About US
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="senior leadership"
                  className="nav-link d-none d-md-inline-block"
                  to="/senior-leadership"
                >
                  Senior Leadership
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="board of directors"
                  className="nav-link d-none d-md-inline-block"
                  to="/board-of-directors"
                >
                  Board of Directors
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="international franchises"
                  className="nav-link d-none d-md-inline-block"
                  to="/international-franchises"
                >
                  International Franchises
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="careers"
                  className="nav-link navbar-brand"
                  to="/careers"
                >
                  CAREERS
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="careers - corporate"
                  className="nav-link d-none d-md-inline-block"
                  to="/careers#corporate"
                >
                  Corporate
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="careers - stores"
                  className="nav-link d-none d-md-inline-block"
                  to="/careers#stores"
                >
                  Stores
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="careers - distribution"
                  className="nav-link d-none d-md-inline-block"
                  to="/careers#distribution"
                >
                  Distribution Center
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="careers - international"
                  className="nav-link d-none d-md-inline-block"
                  to="/careers#international"
                >
                  International
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="us-internships"
                  className="nav-link d-none d-md-inline-block"
                  to="/us-internships"
                >
                  US Internships
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="hk internships"
                  className="nav-link d-none d-md-inline-block"
                  to="/hong-kong-internships"
                >
                  HK Internships
                </Link>
              </li>
              <li class="nav-item">
                <a
                  className="nav-link navbar-brand"
                  href="https://newsplace.childrensplace.com/"
                >
                  NEWSPLACE
                </a>
              </li>
              <li class="nav-item">
                <a
                  className="nav-link navbar-brand"
                  href="http://investor.childrensplace.com/"
                  target="_blank"
                >
                  INVESTOR RELATIONS
                </a>
                <a
                  className="nav-link d-none d-md-inline-block"
                  href="http://investor.childrensplace.com/"
                  target="_blank"
                >
                  Corporate Overview
                </a>
                <a
                  className="nav-link d-none d-md-inline-block"
                  href="http://investor.childrensplace.com/financial-information/annuals-proxies"
                  target="_blank"
                >
                  Financial Reports
                </a>
                <a
                  className="nav-link d-none d-md-inline-block"
                  href="http://investor.childrensplace.com/news-events/press-releases"
                  target="_blank"
                >
                  Press Release
                </a>
              </li>
              <li class="nav-item">
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="ESG"
                  className="nav-link navbar-brand"
                  to="/esg"
                >
                  ESG
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="environment"
                  className="nav-link d-none d-md-inline-block"
                  to="/environment"
                >
                  Environment
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="social"
                  className="nav-link d-none d-md-inline-block"
                  to="/social"
                >
                  Social
                </Link>
                <Link
                  onClick={this.fireNavLink}
                  data-navlink="governance"
                  className="nav-link d-none d-md-inline-block"
                  to="/governance"
                >
                  Governance
                </Link>
              </li>
              <li class="nav-item">
                <a
                  className="nav-link navbar-brand d-block"
                  href="https://www.linkedin.com/company/the-children's-place/"
                >
                  Follow Us
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
                <a
                  onClick={this.fireAnalytics}
                  data-social="linkedIn"
                  className="nav-link d-inline pr-2"
                  href="https://www.linkedin.com/company/the-children's-place/"
                >
                  <img src={social4} className="" alt="linkedin" />
                </a>
                <a
                  onClick={this.fireAnalytics}
                  data-social="facebook"
                  className="nav-link d-inline pr-2"
                  href="https://www.facebook.com/childrensplace/"
                >
                  <img src={social2} className="" alt="facebook" />
                </a>
                <a
                  onClick={this.fireAnalytics}
                  social-data="instagram"
                  className="nav-link d-inline pr-2"
                  href="https://www.instagram.com/childrensplace"
                >
                  <img src={social5} className="" alt="instagram" />
                </a>
                <a
                  onClick={this.fireAnalytics}
                  social-data="twitter"
                  className="nav-link d-inline pr-2"
                  href="https://twitter.com/childrensplace"
                >
                  <img src={social1} className="" alt="twitter" />
                </a>
                <a
                  className="nav-link d-inline pr-2"
                  href="https://www.pinterest.com/childrensplace/"
                >
                  <img src={social3} className="" alt="pinterest" />
                </a>
                <hr class="d-md-none" />
                <a
                  className="nav-link dark pt-4"
                  href="https://www.childrensplace.com"
                >
                  Shop The Children's Place
                </a>
                <a
                  class=" nav-link dark d-md-none"
                  href="https://www.childrensplace.com/us/help-center/#privacyPolicySectionli"
                >
                  Security &amp; Privacy
                </a>
                <a
                  class=" nav-link dark d-md-none"
                  href="https://www.childrensplace.com/us/help-center/#termsAndConditionsli"
                >
                  Legal Notices
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div class="row footer-date mb-4">
          <div class="col-md-7 col-sm-12">
            © {new Date().getFullYear()},{` `}
            <a href="https://www.childrensplace.com">The Children's Place</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="font-italic">Big Fashion, Little Prices</span>
          </div>
          <div class="col-md-5 d-none d-md-block">
            <div class="row">
              <div class="col-4 pl-1 pr-1 text-right">
                <a
                  class="dark"
                  href="https://www.childrensplace.com/us/help-center/#termsAndConditionsli"
                >
                  Terms and Conditions
                </a>
              </div>
              <div class="col-2 pl-1 pr-1 text-right">
                <a
                  class="dark"
                  href="https://www.childrensplace.com/us/help-center/#privacyPolicySectionli"
                >
                  Privacy Policy
                </a>
              </div>
              <div class="col-2 pl-1 pr-1 text-right">
                <a
                  class="dark"
                  href="https://www.childrensplace.com/us/help-center/#cookiePolicySectionli"
                >
                  Cookie Policy
                </a>
              </div>
              <div class="col-4 pl-1 pr-1 text-right">
                <a
                  class="dark"
                  href="https://www.childrensplace.com/us/content/supply-chain"
                >
                  California Supply Chains Act
                </a>
              </div>
            </div>
          </div>

          <div class="col-md-7 col-sm-12"></div>

          <div class="col-md-5 d-none d-md-block">
            <div class="row">
              <div class="col-4 pl-1 pr-1 text-right"></div>
              <div class="col-2 pl-1 pr-1 text-right"></div>
              <div class="col-2 pl-1 pr-1 text-right">
                <a
                  class="dark"
                  href="https://health1.aetna.com/app/public/#/one/insurerCode=AETNACVS_I&brandCode=ALICSI/machine-readable-transparency-in-coverage?searchTerm=112398708&lock=true"
                >
                  Aetna
                </a>
              </div>
              <div class="col-4 pl-1 pr-1 text-right">
                <a
                  class="dark"
                  href="https://d3oz7y1cwsecds.cloudfront.net/group-prod/bcbsal"
                >
                  BlueCross BlueShield of Alabama{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
