import { Link } from 'gatsby'
import React from 'react'
// import logo from '../assets/images/Logo_tribrand.png'
import logo from '../assets/images/quad-logo-horizontal@3x.png'
import { getNavClickDetails } from '../analytics'

class Menu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.fireNavLink = this.fireNavLink.bind(this)
  }

  fireNavLink(e) {
    getNavClickDetails(e.currentTarget.dataset.navlink, 'header')
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand" to="/">
              <img src={logo} className="d-inline-block align-top" alt="" />
            </Link>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item dropdown-toggler">
                  <Link
                    onClick={this.fireNavLink}
                    data-navlink="home"
                    className="nav-link"
                    to="/"
                    activeClassName="active"
                  >
                    HOME
                  </Link>
                </li>

                <li className="nav-item dropdown-toggler">
                  <Link
                    onClick={this.fireNavLink}
                    data-navlink="chairmans-letters"
                    className="nav-link"
                    to="/chairmans-letters"
                    activeClassName="active"
                  >
                    CHAIRMAN'S LETTERS
                  </Link>
                </li>

                <li className="nav-item dropdown-toggler">
                  <Link
                    onClick={this.fireNavLink}
                    data-navlink="who-we-are"
                    className="nav-link"
                    to="/who-we-are"
                    activeClassName="active"
                  >
                    WHO WE ARE
                  </Link>
                  <div className="dropdown-box dropdown-menu">
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="who-we-are:about-us"
                      className="nav-link dropdown-item"
                      to="/about-us"
                    >
                      ABOUT US
                    </Link>
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="who-we-are:senior-leadership"
                      className="nav-link dropdown-item"
                      to="/senior-leadership"
                    >
                      SENIOR LEADERSHIP
                    </Link>
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="who-we-are:board-of-directors"
                      className="nav-link dropdown-item"
                      to="/board-of-directors"
                    >
                      BOARD OF DIRECTORS
                    </Link>
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="who-we-are:international-franchises"
                      className="nav-link dropdown-item"
                      to="/international-franchises"
                    >
                      INTERNATIONAL FRANCHISES
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown-toggler">
                  <Link
                    onClick={this.fireNavLink}
                    data-navlink="careers"
                    className="nav-link"
                    to="/careers"
                    activeClassName="active"
                  >
                    CAREERS
                  </Link>
                  <div className="dropdown-box dropdown-menu">
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="careers:global-careers"
                      className="nav-link dropdown-item"
                      to="/careers"
                    >
                      GLOBAL CAREERS
                    </Link>
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="careers:us-internships"
                      className="nav-link dropdown-item"
                      to="/us-internships"
                    >
                      US INTERNSHIPS
                    </Link>
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="careers:hong-kong-internships"
                      className="nav-link dropdown-item"
                      to="/hong-kong-internships"
                    >
                      HONG KONG INTERNSHIPS
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown-toggler">
                  <Link
                    onClick={this.fireNavLink}
                    data-navlink="careers"
                    className="nav-link"
                    to="https://newsplace.childrensplace.com/"
                    activeClassName="active"
                  >
                    NEWSPLACE
                  </Link>
                </li>
                <li className="nav-item dropdown-toggler">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="http://investor.childrensplace.com/"
                    activeClassName="active"
                  >
                    INVESTOR RELATIONS
                  </a>
                  <div className="dropdown-box dropdown-menu">
                    <a
                      className="nav-link dropdown-item"
                      target="_blank"
                      href="http://investor.childrensplace.com/"
                    >
                      CORPORATE OVERVIEW
                    </a>
                    <a
                      className="nav-link dropdown-item"
                      target="_blank"
                      href="http://investor.childrensplace.com/financial-information/annuals-proxies"
                    >
                      FINANCIAL REPORTS
                    </a>
                    <a
                      className="nav-link dropdown-item"
                      target="_blank"
                      href="http://investor.childrensplace.com/news-events/press-releases"
                    >
                      PRESS RELEASES
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown-toggler">
                  <Link
                    onClick={this.fireNavLink}
                    data-navlink="environment"
                    className="nav-link"
                    to="/esg"
                    activeClassName="active"
                  >
                    ESG
                  </Link>
                  <div className="dropdown-box dropdown-menu">
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="environment"
                      className="nav-link dropdown-item"
                      to="/environment"
                    >
                      ENVIRONMENT
                    </Link>
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="social"
                      className="nav-link dropdown-item"
                      to="/social"
                      activeClassName="active"
                    >
                      SOCIAL
                    </Link>
                    <Link
                      onClick={this.fireNavLink}
                      data-navlink="governance"
                      className="nav-link dropdown-item"
                      to="/governance"
                      activeClassName="active"
                    >
                      GOVERNANCE
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown-toggler shop-bg">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="#"
                    activeClassName="active"
                  >
                    SHOP
                  </a>
                  <a
                    className="nav-link visible-xs"
                    target="_blank"
                    href="https://www.childrensplace.com"
                    activeClassName="active"
                  >
                    The Children's Place
                  </a>
                  <a
                    className="nav-link visible-xs"
                    target="_blank"
                    href="https://www.gymboree.com"
                    activeClassName="active"
                  >
                    Gymboree
                  </a>
                  <a
                    className="nav-link visible-xs"
                    target="_blank"
                    href="https://www.childrensplace.com/us/c/tween-girls-clothing"
                    activeClassName="active"
                  >
                    Sugar &amp; Jade
                  </a>
                  <a
                    className="nav-link visible-xs"
                    target="_blank"
                    href="https://www.childrensplace.com/us/c/shop-all-kids-pajamas"
                    activeClassName="active"
                  >
                    PJ Place
                  </a>
                  <div className="dropdown-box dropdown-menu">
                    <a
                      className="nav-link dropdown-item"
                      target="_blank"
                      href="https://www.childrensplace.com"
                    >
                      The Children's Place
                    </a>
                    <a
                      className="nav-link dropdown-item"
                      target="_blank"
                      href="https://www.gymboree.com"
                    >
                      Gymboree
                    </a>
                    <a
                      className="nav-link dropdown-item"
                      target="_blank"
                      href="https://www.childrensplace.com/us/c/tween-girls-clothing"
                    >
                      Sugar &amp; Jade
                    </a>
                    <a
                      className="nav-link dropdown-item"
                      target="_blank"
                      href="https://www.childrensplace.com/us/c/shop-all-kids-pajamas"
                    >
                      PJ Place
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default Menu
