    export const setLocalStorage = function (arg) {
        let {key, value} = arg;
        return window.localStorage.setItem(key, value);
    };
  
  /**
   * Returns data stored in localstorage
   * @param {string} key - Localstorage item key
   * @returns {string} - Localstorage item data
   */
  export const getLocalStorage = function (key) {
    return window.localStorage.getItem(key);
  };
  
  /**
   * Remove data from localstorage
   * @param {string} key - Localstorage item key
   */
  export const removeLocalStorage = function (key) {
    return window.localStorage.removeItem(key);
  };