import { setLocalStorage, getLocalStorage } from './localStorageManagement';

export function trackPageData(data) {
    if (typeof window._satellite !== "undefined") {
        if(typeof window._satellite.track === "function") {
          window._satellite.track('basicLoadEvent', data);  
        }
      }
}

export function trackClickData(data) {
    if (typeof window._satellite !== "undefined") {
        if(typeof window._satellite.track === "function") {
          window._satellite.track('basicClickEvent', data);  
        }
      }
}

function getLinkTrackVars() {
    const s = getOmniture();
    let linkTrackVars = "";

    if(s !== undefined) {
        if(s.linkTrackVars !== "" && s.linkTrackVars.split(",").indexOf("events") === -1) {
            linkTrackVars = s.linkTrackVars + ",events";
        } else {
            linkTrackVars = "events";
        }
    }
    return linkTrackVars;    
}

export function getNavClickDetails(target, loc) {
    const _data = window.dataManager;
    const s = getOmniture();

    if(s !== undefined) {
        s.linkTrackVars = getLinkTrackVars();
        s.linkTrackEvents = s.linkTrackEvents !== undefined ? s.linkTrackEvents + "event82" : "event82";
        s.events = s.events + "event82";
    }

    if (_data && typeof _data.pageDetails === "object") {
        setLocalStorage({key: 'navLinkName', value: `${target}:${loc}`});
    }
}

function getOmniture () {
    return window.s ? window.s : window.s_c_il && window.s_c_il[0] ? window.s_c_il[0] : null;
}

export function trackSocialShare(data) {
    const _data = window.dataManager;
    const s = getOmniture();

    if (_data && typeof _data.pageDetails === "object") {
        _data.pageDetails.socialShare = _data.pageDetails.linkName = data;
    }
    s.linkTrackVars = getLinkTrackVars();
    s.linkTrackEvents = s.linkTrackEvents !== undefined ? s.linkTrackEvents + "event84" : "event84";
    s.events = s.events + "event84";
    trackClickData();
}


export function getPageDetails() {
  try {
    const location = window.location;
    const _hash = location.hash;
    const urlPathName = location.pathname;
    let pageName = "";
    let primaryCategory = "";
    
    if (urlPathName.indexOf('/') > -1) {
        pageName = 'homePage';
        } if (urlPathName.indexOf('/who-we-are') > -1) {
            pageName = 'who we are';
            primaryCategory = "who we are";
        } else if (urlPathName.indexOf('/about-us') > -1) {
            pageName = 'who we are: about us';
            primaryCategory = "who we are";
        } else if (urlPathName.indexOf('/senior-leadership') > -1) {
            pageName = 'who we are: senior leadership';
            primaryCategory = "who we are";
        }
        
        else if (urlPathName.indexOf('/board-of-directors') > -1) {
            pageName = 'who we are: board of directors';
            primaryCategory = "who we are";
        } 
        else if (urlPathName.indexOf('/international-franchises') > -1) {
            pageName = 'who we are: international franchises';
            primaryCategory = "who we are";
        } else if (urlPathName.indexOf('/corporate-responsibility') > -1) {
            pageName = 'corporate-responsibility';
            primaryCategory = "corporate-responsibility";
        } else if (urlPathName.indexOf('/responsible-sourcing') > -1) {
            pageName = 'corporate-responsibility: responsible-sourcing';
            primaryCategory = "corporate-responsibility";
        } else if (urlPathName.indexOf('/philanthropic-programs') > -1) {
            pageName = 'corporate-responsibility: philanthropic-programs';
            primaryCategory = "corporate-responsibility";
        } else if (urlPathName.indexOf('/us-internships') > -1) {
            pageName = 'careers: us-internships';
            primaryCategory = "careers";
        } else if (urlPathName.indexOf('/hong-kong-internships') > -1) {
            pageName = 'careers: hong-kong-internships';
            primaryCategory = "careers";
        } else if (urlPathName.indexOf('/careers') > -1 || _hash === "#stores" || _hash === "distribution") {
            pageName = 'careers';
            primaryCategory = "careers";
        } else if (urlPathName.indexOf('/404') > -1) {
            pageName = 'errorPage';
            primaryCategory = "errorPage";
        }
     
    return {
        pageName : pageName,
        primaryCategory: primaryCategory,
        navLinkName: getLocalStorage('navLinkName')
    };

    } catch (ex) {
        console.warn('DTM: omniture.global.pageName failed', ex);
    }

}